import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {
	Box,
	Button,
	Divider,
	Grid,
	Stack,
	Typography, useMediaQuery
} from '@mui/material';
import UserApi from "../../api/UserApi";
import AnimateButton from "../ui/extended/AnimateButton";
import {useTheme} from "@mui/material/styles";
import AuthMainWrapper from "./AuthMainWrapper";


export default function ActivateAccount() {
	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
	let { hash } = useParams();


	useEffect(()=>{
		activateAccountWithHash()
	})

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [registerText, setRegisterText] = useState('');

	const activateAccountWithHash = async () => {
		setIsSubmitting(true);
		UserApi.activateAccount({
			hash:hash,
		}).then(async (response)=>{
			if(response.data.success === true){
				setRegisterText("Contul a fost activat.")
			}
		}).finally(()=>{
			setIsSubmitting(false);
		})
	}

	return (
		<AuthMainWrapper>
			<Grid item xs={12}>
				<Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
					<Grid item>
						<Stack alignItems="center" justifyContent="center" spacing={1}>
							<Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
								Activare cont
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>

				<Box sx={{ mt: 2 }}>
					{registerText.length === 0 ? (
						<AnimateButton>
							<Button
								disableElevation
								disabled={isSubmitting}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
								color="secondary"
							>
								Activare
							</Button>
						</AnimateButton>
					) : (
						<Typography variant="subtitle1">
							{registerText}
						</Typography>
					)}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<Grid item container direction="column" alignItems="center" xs={12}>
					<Typography component={Link} to="/login" variant="subtitle1" sx={{ textDecoration: 'none' }}>
						Mergi la login
					</Typography>
				</Grid>
			</Grid>
		</AuthMainWrapper>
	)
}