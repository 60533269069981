import axios from "../util/axios";

export const endpoints = {
	auth: {
		me: '/api/users/me',
		login: '/api/users/login',
		register: '/api/users/register',
		activateAccount: '/api/users/activateAccount',
		passwordForgotten: '/api/users/passwordForgotten',
		checkPasswordHash: '/api/users/checkPasswordHash',
		setPassword: '/api/users/setPassword',
	},
	getCompanyData: '/api/users/company/getData',
	updateCompanyData: '/api/users/company/updateData',
	getDefaultFiles: '/api/users/files/default',
	getGrantFiles: '/api/users/files/grant',
	getUserFiles: '/api/users/files/getData',
	uploadUserFiles: '/api/users/files/uploadData',
	startVerifying: '/api/users/startVerifying',
	reject: '/api/users/reject',
};

const UserApi = {
	async login(data){
		return await axios.post(
			endpoints.auth.login,
			data
		)
	},
	async register(data){
		return await axios.post(
			endpoints.auth.register,
			data
		)
	},
	async activateAccount(data){
		return await axios.post(
			endpoints.auth.activateAccount,
			data
		)
	},
	async startVerifying(token){
		return await axios.post(
			endpoints.startVerifying,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async reject(token){
		return await axios.post(
			endpoints.reject,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getMe(token){
		return await axios.post(
			endpoints.auth.me,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getCompanyData(token){
		return await axios.post(
			endpoints.getCompanyData,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async updateCompanyData(data, token){
		return await axios.post(
			endpoints.updateCompanyData,
			data,
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getDefaultFiles(token){
		return await axios.post(
			endpoints.getDefaultFiles,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getGrantFiles(token){
		return await axios.post(
			endpoints.getGrantFiles,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getUserFiles(token){
		return await axios.post(
			endpoints.getUserFiles,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async uploadUserFiles(data, token){
		return await axios.post(
			endpoints.uploadUserFiles,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async passwordForgotten(data){
		return await axios.post(
			endpoints.auth.passwordForgotten,
			data
		)
	},
	async checkPasswordHash(data){
		return await axios.post(
			endpoints.auth.checkPasswordHash,
			data
		)
	},
	async setPassword(data){
		return await axios.post(
			endpoints.auth.setPassword,
			data
		)
	},
}

export default UserApi