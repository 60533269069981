import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Stack,
	Typography, useMediaQuery
} from '@mui/material';
import UserApi from "../../api/UserApi";
import AnimateButton from "../ui/extended/AnimateButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import AuthMainWrapper from "./AuthMainWrapper";

export default function PasswordForgotten2() {
	const theme = useTheme();
	const navigate = useNavigate();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

	const [pfPassword, setPfPassword] = useState('');
	const [pfPasswordError, setPfPasswordError] = useState('');
	const [pfPassword2, setPfPassword2] = useState('');
	const [pfPassword2Error, setPfPassword2Error] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showPassword2, setShowPassword2] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [mainError, setMainError] = useState('');

	let { hash } = useParams();

	useEffect(()=>{
		if(!loaded){
			setLoaded(true);
			UserApi.checkPasswordHash({
				hash: hash,
			}).then(async (response)=>{
				if(!response.data.success){
					navigate('/login');
				}
			})
		}
	}, [loaded, hash, navigate])

	const handlePfClick = async () => {
		if(checkPfData()){
			setIsSubmitting(true);
			UserApi.setPassword({
				hash: hash,
				password1: pfPassword,
				password2: pfPassword2,
			}).then(async (response)=>{
				if(response.data.success){
					alert("Noua parola a fost salvata")
					navigate('/login');
				}
			}).catch((err)=>{
				if (err.code && err.code === 401) {
					setMainError(err.errors[0]);
					setTimeout(()=>{setMainError("");},5000);
				}
			}).finally(()=>{
				setIsSubmitting(false);
			})
		}
	}

	const checkPfData = () => {
		let errors = false;
		if(pfPassword.length<6){
			setPfPasswordError("Parola trebuie sa contina cel putin 6 caractere");
			setTimeout(()=>{setPfPasswordError("");},5000);
			errors = true;
		}
		if(pfPassword2.length<6){
			setPfPassword2Error("Parola trebuie sa contina cel putin 6 caractere");
			setTimeout(()=>{setPfPassword2Error("");},5000);
			errors = true;
		}

		if(pfPassword !== pfPassword2){
			setPfPasswordError("Parolele nu sunt la fel");
			setPfPassword2Error("Parolele nu sunt la fel");
			setTimeout(()=>{
				setPfPasswordError("");
				setPfPassword2Error("");
				},5000);
		}

		return !errors;
	}

	return (
		<AuthMainWrapper>
			<Grid item xs={12}>
				<Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
					<Grid item>
						<Stack alignItems="center" justifyContent="center" spacing={1}>
							<Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
								Schimbare parola
							</Typography>
							<Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
								Introduce noua parola
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth error={Boolean(pfPasswordError)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="password-pf">Parola</InputLabel>
					<OutlinedInput
						id="password-pf"
						type={showPassword ? 'text' : 'password'}
						value={pfPassword}
						name="password"
						onChange={event=>setPfPassword(event.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={()=>setShowPassword(!showPassword)}
									edge="end"
									size="large"
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
						label="Parola"
						inputProps={{}}
					/>
					{pfPasswordError && (
						<FormHelperText error id="standard-weight-helper-text-password-pf">
							{pfPasswordError}
						</FormHelperText>
					)}
				</FormControl>

				<FormControl fullWidth error={Boolean(pfPassword2Error)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="password-pf">Parola din nou</InputLabel>
					<OutlinedInput
						id="password-pf"
						type={showPassword2 ? 'text' : 'password'}
						value={pfPassword2}
						name="password"
						onChange={event=>setPfPassword2(event.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={()=>setShowPassword2(!showPassword2)}
									edge="end"
									size="large"
								>
									{showPassword2 ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
						label="Parola"
						inputProps={{}}
					/>
					{pfPassword2Error && (
						<FormHelperText error id="standard-weight-helper-text-password-pf">
							{pfPassword2Error}
						</FormHelperText>
					)}
				</FormControl>

				<Box sx={{ mt: 2 }}>
					<AnimateButton>
						<Button
							disableElevation
							disabled={isSubmitting}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							color="secondary"
							onClick={handlePfClick}
						>
							Salveaza noua parola
						</Button>
					</AnimateButton>
					{mainError}
				</Box>
			</Grid>
		</AuthMainWrapper>
	)
}