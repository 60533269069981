import React from "react";
import {Link} from "react-router-dom";
import {
	Divider,
	Grid,
	Typography
} from '@mui/material';
import AuthMainWrapper from "./AuthMainWrapper";


export default function Register() {
	return (
		<AuthMainWrapper>
			<Grid item xs={12} style={{fontWeight: "bold"}}>
				Sesiunea de inscriere s-a finalizat!<br />
				Va multumim pt interes si va asteptam la TIMM Cluj in perioada 22-24 noiembrie , organizat la Cluj Innovation Park!
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<Grid item container direction="column" alignItems="center" xs={12}>
					<Typography component={Link} to="/login" variant="subtitle1" sx={{ textDecoration: 'none' }}>
						Ai cont?
					</Typography>
				</Grid>
			</Grid>
		</AuthMainWrapper>
	)
}