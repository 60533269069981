import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {Avatar, Box, Button, ButtonBase} from '@mui/material';
import LogoSection from '../LogoSection';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch} from "react-redux";
import {deactivateSession, setToken, setUser} from "../../../store/userSlice";
import {useNavigate} from "react-router-dom";

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
      dispatch(setUser({}))
      dispatch(setToken(''))
      dispatch(deactivateSession())
      localStorage.removeItem('x-api-token');
      navigate('/login');
  }

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
          <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
              <Avatar
                  variant="rounded"
                  sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.mediumAvatar,
                      transition: 'all .2s ease-in-out',
                      background: theme.palette.secondary.light,
                      color: theme.palette.secondary.dark,
                      '&:hover': {
                          background: theme.palette.secondary.dark,
                          color: theme.palette.secondary.light
                      }
                  }}
                  onClick={handleLeftDrawerToggle}
                  color="inherit"
              >
                  <MenuIcon stroke={1.5} size="1.3rem" />
              </Avatar>
          </ButtonBase>
      </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />
        <Button
            variant="contained"
            disableElevation
            startIcon={<LogoutIcon stroke={1.5} size="1.3rem" />}
            sx={{
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light
                }
            }}
            onClick={handleLogout}
        >
            Logout
        </Button>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
