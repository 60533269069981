import {
	Button,
	FormControl, FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem, Radio, RadioGroup,
	Select,
	TextField
} from '@mui/material';
import MainCard from '../ui/cards/MainCard';

import judete from '../../data/judete.json';
import {useEffect, useState} from "react";
import UserApi from "../../api/UserApi";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../store/userSlice";

const initialData = {
	nume:'',
	cui:'',
	caen:'',
	judet:'',
	adresa:'',
	email:'',
	telefon:'',
	legal_nume:'',
	legal_pozitie:'',
	onrc:'',
	categorie:'',
	contact_nume:'',
	contact_telefon:'',
	contact_email:'',
	stand:'',
	intentie:'',
}

const categorii = [
	'Microîntreprindere',
	'Întreprindere mică',
	'Întreprindere mijlocie',
]

const Company = () => {
	const [data, setData] = useState(initialData)
	const [errorData, setErrorData] = useState(initialData)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const { token } = useSelector(state => state.user);
	const [loaded, setLoaded] = useState(false);
	const dispatch = useDispatch();
	const { data:dataUser } = useSelector(state => state.user);

	useEffect(()=>{
		if(!loaded){
			UserApi.getCompanyData(token).then(async (response)=>{
				const company_data = response.data.user_company;
				if(company_data.id) {
					setData(company_data);
				}
			}).catch((err)=>{
				console.log("err",err);
			}).finally(()=>{
				setLoaded(true);
			})
		}
	},[loaded, token])

	const handleDataChange = (propName, newValue) => {
		setData((prevState)=>{
			return {
				...prevState,
				[propName]:newValue
			}
		});

		setErrorData((prevState)=>{
			return {
				...prevState,
				[propName]:''
			}
		})
	}



	const handleSave = async () => {
		setIsSubmitting(true);
		const errors = await testData();
		if(Object.values(errors).length>0){
			setIsSubmitting(false);
			return;
		}

		UserApi.updateCompanyData(data,token).then(async (response)=>{
			//todo cum aratam ca e ok ce a trimis?
			console.log('response',response);

			UserApi.getMe(localStorage.getItem('x-api-token')).then(async (response)=>{
				await dispatch(setUser(response.data.user));
			})

		}).catch((err)=>{
			console.log("err",err);
		}).finally(()=>{
			setIsSubmitting(false);
		})
	}

	const testData = async () => {
		const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		const newErrors = {};

		if(data.nume.length < 2 ){
			newErrors.nume = "Invalid"
		}
		if(data.cui.length < 2 ){
			newErrors.cui = "Invalid"
		}
		if(data.caen.length < 2 ){
			newErrors.caen = "Invalid"
		}
		if(data.judet.length < 2 ){
			newErrors.judet = "Invalid"
		}
		if(data.adresa.length < 2 ){
			newErrors.adresa = "Invalid"
		}
		if( !data.email.match(validEmailRegex) ){
			newErrors.email = "Invalid"
		}
		if(data.telefon.length < 2 ){
			newErrors.telefon = "Invalid"
		}
		if(data.legal_nume.length < 2 ){
			newErrors.legal_nume = "Invalid"
		}
		if(data.legal_pozitie.length < 2 ){
			newErrors.legal_pozitie = "Invalid"
		}
		if(data.onrc.length < 2 ){
			newErrors.onrc = "Invalid"
		}
		if(data.categorie.length < 2 ){
			newErrors.categorie = "Invalid"
		}
		if(data.contact_nume.length < 2 ){
			newErrors.contact_nume = "Invalid"
		}
		if(data.contact_telefon.length < 2 ){
			newErrors.contact_telefon = "Invalid"
		}
		if( !data.contact_email.match(validEmailRegex) ){
			newErrors.contact_email = "Invalid"
		}

		if(data.stand === '' ){
			newErrors.stand = "Invalid"
		}
		if(data.intentie.length < 2 ){
			newErrors.intentie = "Invalid"
		}

		setErrorData((prevState) => {
			return {
				...prevState,
				...newErrors
			}
		});

		return newErrors
	}

	return (
		<MainCard
			title="Formular de inscriere"
		>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={8}>
					<TextField
						label="Denumire completa solicitant"
						fullWidth={true}
						value={data.nume}
						onChange={event=>handleDataChange('nume', event.target.value)}
						error={errorData.nume.length>0}
						helperText={errorData.nume}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<FormControl fullWidth error={errorData.categorie.length>0}>
						<InputLabel id="label-categorie">Categoria solicitantului</InputLabel>
						<Select
							labelId="label-categorie"
							value={data.categorie}
							onChange={event=>handleDataChange('categorie', event.target.value)}
							label="Categoria solicitantului"
						>
							<MenuItem value="" disabled={true}>Alege categoria</MenuItem>
							{categorii.map(categorie=>{
								return <MenuItem key={categorie} value={categorie}>{categorie}</MenuItem>
							})}
						</Select>
						{errorData.categorie.length > 0 &&
							<FormHelperText>{errorData.categorie}</FormHelperText>
						}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						label="Cui"
						fullWidth={true}
						value={data.cui}
						onChange={event=>handleDataChange('cui', event.target.value)}
						error={errorData.cui.length>0}
						helperText={errorData.cui}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						label="Nr. de inreg ONRC"
						fullWidth={true}
						value={data.onrc}
						onChange={event=>handleDataChange('onrc', event.target.value)}
						error={errorData.onrc.length>0}
						helperText={errorData.onrc}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						label="Codul CAEN accesare program"
						fullWidth={true}
						value={data.caen}
						onChange={event=>handleDataChange('caen', event.target.value)}
						error={errorData.caen.length>0}
						helperText={errorData.caen}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth error={errorData.judet.length>0}>
						<InputLabel id="label-judet">Judet</InputLabel>
						<Select
							labelId="label-judet"
							value={data.judet}
							label="Judet"
							onChange={event=>handleDataChange('judet', event.target.value)}
						>
							<MenuItem value="" disabled={true}>Alege judetul</MenuItem>
							{judete.sort((a,b)=>a.name>b.name?1:-1).map(judet=>{
								return <MenuItem key={judet.name} value={judet.name}>{judet.name}</MenuItem>
							})}
						</Select>
						{errorData.judet.length > 0 &&
							<FormHelperText>{errorData.judet}</FormHelperText>
						}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Adresa"
						fullWidth={true}
						value={data.adresa}
						onChange={event=>handleDataChange('adresa', event.target.value)}
						error={errorData.adresa.length>0}
						helperText={errorData.adresa}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Nume reprezentant legal"
						fullWidth={true}
						value={data.legal_nume}
						onChange={event=>handleDataChange('legal_nume', event.target.value)}
						error={errorData.legal_nume.length>0}
						helperText={errorData.legal_nume}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Functia reprezentantului legal"
						fullWidth={true}
						value={data.legal_pozitie}
						onChange={event=>handleDataChange('legal_pozitie', event.target.value)}
						error={errorData.legal_pozitie.length>0}
						helperText={errorData.legal_pozitie}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<TextField
						label="Telefon"
						fullWidth={true}
						value={data.telefon}
						onChange={event=>handleDataChange('telefon', event.target.value)}
						error={errorData.telefon.length>0}
						helperText={errorData.telefon}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Email"
						fullWidth={true}
						value={data.email}
						onChange={event=>handleDataChange('email', event.target.value)}
						error={errorData.email.length>0}
						helperText={errorData.email}
					/>
				</Grid>

				<Grid item xs={12} sm={4}>
					<TextField
						label="Nume de contact"
						fullWidth={true}
						value={data.contact_nume}
						onChange={event=>handleDataChange('contact_nume', event.target.value)}
						error={errorData.contact_nume.length>0}
						helperText={errorData.contact_nume}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						label="Telefon de contact"
						fullWidth={true}
						value={data.contact_telefon}
						onChange={event=>handleDataChange('contact_telefon', event.target.value)}
						error={errorData.contact_telefon.length>0}
						helperText={errorData.contact_telefon}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<TextField
						label="Email de contact"
						fullWidth={true}
						value={data.contact_email}
						onChange={event=>handleDataChange('contact_email', event.target.value)}
						error={errorData.contact_email.length>0}
						helperText={errorData.contact_email}
					/>
				</Grid>

				<Grid item xs={12}>
					<FormControl error={errorData.stand.length>0}>
						<FormLabel id="amenajare_stand">Pentru participarea la TIMM 2023 imi voi amenaja in regim propiu standul asocietatii</FormLabel>
						<RadioGroup
							row
							aria-labelledby="amenajare_stand"
							name="stand"
						>
							<FormControlLabel
								value="Da"
								control={<Radio />}
								label="Da"
								checked={data.stand === "da"}
								onChange={()=>handleDataChange('stand', 'da')}
							/>
							<FormControlLabel
								value="Nu"
								control={<Radio />}
								label="Nu"
								checked={data.stand === "nu"}
								onChange={()=>handleDataChange('stand', 'nu')}
							/>
						</RadioGroup>

						{errorData.stand.length > 0 &&
							<FormHelperText>{errorData.stand}</FormHelperText>
						}
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="Descrieţi produsele/serviciile pe care doriţi să le promovaţi în cadrul targului"
						fullWidth={true}
						value={data.intentie}
						onChange={event=>handleDataChange('intentie', event.target.value)}
						error={errorData.intentie.length>0}
						helperText={errorData.intentie}
						multiline={true}
						rows={3}
					/>
				</Grid>
			</Grid>
			<Grid container sx={{
				justifyContent: 'space-between',
				marginTop: '24px',
			}}>
				<div> </div>
				{!dataUser.LockCompany &&
					<Button
						onClick={handleSave}
						variant="contained"
						disabled={isSubmitting}
					>
						Salveaza
					</Button>
				}
			</Grid>
		</MainCard>
	);
};

export default Company;
