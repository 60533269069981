import {Button, FormHelperText, Grid, Typography} from '@mui/material';
import SubCard from '../ui/cards/SubCard';
import MainCard from '../ui/cards/MainCard';
import {useEffect, useState} from "react";
import UserApi from "../../api/UserApi";
import {useDispatch, useSelector} from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import Dropzone from "react-dropzone";
import {useTheme} from "@mui/material/styles";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {setUser} from "../../store/userSlice";

const Files = () => {
	const [defaultFiles, setDefaultFiles] = useState([])
	const [userFiles, setUserFiles] = useState([])
	const [data, setData] = useState({})
	const [loaded, setLoaded] = useState(false)
	const { token } = useSelector(state => state.user)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorData, setErrorData] = useState({1:'',2:'',3:'',4:'',5:'',6:'',7:'', 8:''})
	const theme = useTheme();
	const dispatch = useDispatch();
	const { data:dataUser } = useSelector(state => state.user);

	useEffect(()=>{
		if(!loaded){
			UserApi.getDefaultFiles(token).then(async (response)=>{
				const defaultData = response.data.data;
				setDefaultFiles(defaultData);
			}).catch((err)=>{
				console.log("err",err);
			}).finally(()=>{
				setLoaded(true);
			})

			UserApi.getUserFiles(token).then(async (response)=>{
				setUserFiles(response.data.data);
			}).catch((err)=>{
				console.log("err",err);
			}).finally(()=>{
				setLoaded(true);
			})

		}
	},[loaded, token])

	const setFile = (file, position) => {
		setData((prevState)=>{
			return {
				...prevState,
				[position]: file,
			}
		})

		setErrorData((prevState)=>{
			return {
				...prevState,
				[position]:''
			}
		})
	}

	const handleSave = async () => {
		setIsSubmitting(true);
		const errors = await testData();

		if(Object.values(errors).length>0){
			setIsSubmitting(false);
			return;
		}
		UserApi.uploadUserFiles(data, token).then(async (response)=>{
			setUserFiles(response.data.data);
			UserApi.getMe(localStorage.getItem('x-api-token')).then(async (response)=>{
				await dispatch(setUser(response.data.user));
			})
			UserApi.getUserFiles(token).then(async (response)=>{
				setUserFiles(response.data.data);
			}).catch((err)=>{
				console.log("err",err);
			}).finally(()=>{
				setLoaded(true);
			})
			setUserFiles([]);
		}).catch((err)=>{
			console.log("err",err);
		}).finally(()=>{
			setIsSubmitting(false);
		})
	}

	const testData = async () => {
		const userFile1 = userFiles.filter(file=>file.id_default_file === 1 && file.state !== 'rejected')
		const userFile2 = userFiles.filter(file=>file.id_default_file === 2 && file.state !== 'rejected')
		const userFile3 = userFiles.filter(file=>file.id_default_file === 3 && file.state !== 'rejected')
		const userFile5 = userFiles.filter(file=>file.id_default_file === 5 && file.state !== 'rejected')
		const userFile6 = userFiles.filter(file=>file.id_default_file === 6 && file.state !== 'rejected')
		const userFile9 = userFiles.filter(file=>file.id_default_file === 9 && file.state !== 'rejected')

		const newErrors = {};
		if(userFile1.length === 0 && data[1] === undefined  ){
			newErrors[1] = "Invalid"
		}
		if(userFile2.length === 0 && data[2] === undefined  ){
			newErrors[2] = "Invalid"
		}
		if(userFile3.length === 0 && data[3] === undefined  ){
			newErrors[3] = "Invalid"
		}

		if(userFile5.length === 0 && data[5] === undefined  ){
			newErrors[5] = "Invalid"
		}
		if(userFile6.length === 0 && data[6] === undefined ){
			newErrors[6] = "Invalid"
		}
		if(userFile9.length === 0 && data[9] === undefined ){
			newErrors[9] = "Invalid"
		}
		setErrorData(newErrors);

		return newErrors
	}

	const returnFileStatus = (userFile) => {
		if(userFile.state === "pending"){
			return "in procesare.";
		}
		if(userFile.state === "accepted"){
			return "aprobat.";
		}
		if(userFile.state === "rejected"){
			if(userFile.message.length>0){
				return " respins cu mesajul "+userFile.message+".";
			}
			return " respins.";
		}
		return "";
	}

	return (
		<MainCard
			title="Fisiere (in formatul PDF)"
		>
			<Grid container spacing={6}>
				{defaultFiles.map((defaultFile, index) => {
					let fileDb = null;
					let fileUploaded = null;
					let lockDropzone = false;
					const filesFiltered = userFiles.filter(uf=>uf.id_default_file === defaultFile.id);
					if(filesFiltered.length>0){
						fileDb = filesFiltered[0];
						if(fileDb.state === "pending" || fileDb.state === "accepted"){
							lockDropzone = true;
						}
					}
					if(data[defaultFile.id]){
						fileUploaded = data[defaultFile.id];
					}

					let dropzoneBorderColor = '#2196f3';
					if(lockDropzone){
						dropzoneBorderColor = '#e3e3e3';
					}

					let borderColor = theme.palette.primary.light;
					if(errorData[defaultFile.id]){
						borderColor = 'red';
					}
					if(fileDb && fileDb.state === 'accepted'){
						borderColor = 'green';
					}

					let colorText = "black";
					if(fileDb && fileDb.state === "pending"){
						colorText = "orange";
					}
					if(fileDb && fileDb.state === "accepted"){
						colorText = "green";
					}
					if(fileDb && fileDb.state === "rejected"){
						colorText = "red";
					}

					return (
						<Grid item xs={12} sm={6} md={4} key={defaultFile.id}>
							<SubCard title={defaultFile.name} borderColor={borderColor}>
								{defaultFile.path.length > 0 &&
									<Button
										href={process.env.REACT_APP_HOST_API + defaultFile.path}
										download={true}
										variant="contained"
										startIcon={<DownloadIcon/>}
									>
										Descarca formular
									</Button>
								}
								<Dropzone
									multiple={false}
									accept={{
										'application/pdf': ['.pdf']
									}}
									onDrop={acceptedFiles => setFile(acceptedFiles[0], defaultFile.id)}
									disabled={lockDropzone}
								>
									{({getRootProps, getInputProps}) => (
										<section
											{...getRootProps()}
											style={{
												border: `3px dotted ${dropzoneBorderColor}`,
												textAlign: 'center',
												marginTop: '16px',
												padding: '16px',
												cursor: 'pointer',
												opacity: lockDropzone? 0.5 : 1
											}}
										>
											<div >
												<FileUploadIcon />
												<input {...getInputProps()} />
												<p style={{
													margin:0,
													padding:0
												}}>
													Incarca formularul completat
												</p>
											</div>
										</section>
									)}
								</Dropzone>
								{fileUploaded &&
									<Typography variant="h5">
										{fileUploaded.name}
									</Typography>
								}
								{fileDb &&
									<Typography
										variant='h5'
										style={{
											marginTop: "16px",
											fontWeight: 500,
											color: colorText,
										}}
									>
										Fisierul incarcat este {returnFileStatus(fileDb)}
									</Typography>
								}

								{errorData[defaultFile.id] &&
									<FormHelperText className="errorTextField">{errorData[defaultFile.id]}</FormHelperText>
								}

							</SubCard>
						</Grid>
					)
				})}
			</Grid>
			<Grid container sx={{
				justifyContent: 'space-between',
				marginTop: '24px',
			}}>
				<div> </div>
				{!dataUser.LockFiles &&
					<Button
						onClick={handleSave}
						variant="contained"
						disabled={isSubmitting}
					>
						Salveaza
					</Button>
				}
			</Grid>
		</MainCard>
	)
};

export default Files;
