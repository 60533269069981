import React, {useState} from "react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	OutlinedInput,
	Stack,
	Typography, useMediaQuery
} from '@mui/material';
import UserApi from "../../api/UserApi";
import AnimateButton from "../ui/extended/AnimateButton";
import {useTheme} from "@mui/material/styles";
import AuthMainWrapper from "./AuthMainWrapper";


export default function PasswordForgotten() {
	const theme = useTheme();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

	const [loginEmail, setLoginEmail] = useState('');
	const [loginEmailError, setLoginEmailError] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [message, setMessage] = useState('');

	const handleResetPassword = async () => {
		if(checkLoginData()){
			setIsSubmitting(true);
			UserApi.passwordForgotten({
				email:loginEmail,
			}).then(async (response)=>{
				if(response.data.success){
					setMessage("Un email a plecat spre casuta de email continand link-ul de resetare parola")
				}
			}).finally(()=>{
				setIsSubmitting(false);
			})
		}
	}

	const checkLoginData = () => {
		let errors = false;
		if(loginEmail.length===0){
			setLoginEmailError("Invalid");
			setTimeout(()=>{setLoginEmailError("");},5000);
			errors = true;
		}
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		if (!emailRegex.test(loginEmail)) {
			setLoginEmailError("Invalid");
			setTimeout(()=>{setLoginEmailError("");},5000);
			errors = true;
		}

		return !errors;
	}

	return (
		<AuthMainWrapper>
			<Grid item xs={12}>
				<Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
					<Grid item>
						<Stack alignItems="center" justifyContent="center" spacing={1}>
							<Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
								Resetare parola
							</Typography>
							<Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
								Introduce email-ul pentru a continua
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth error={Boolean(loginEmailError)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="email-login">Email</InputLabel>
					<OutlinedInput
						id="email-login"
						type="email"
						value={loginEmail}
						name="email"
						onChange={event=>setLoginEmail(event.target.value)}
						label="Email"
						inputProps={{}}
					/>
					{loginEmailError && (
						<FormHelperText error id="standard-weight-helper-text-email-login">
							{loginEmailError}
						</FormHelperText>
					)}
				</FormControl>

				{message.length === 0 ? (
					<Box sx={{mt: 2}}>
						<AnimateButton>
							<Button
								disableElevation
								disabled={isSubmitting}
								fullWidth
								size="large"
								type="submit"
								variant="contained"
								color="secondary"
								onClick={handleResetPassword}
							>
								Reseteaza
							</Button>
						</AnimateButton>
					</Box>
					) : (
						<Typography variant="subtitle1">
							{message}
						</Typography>
					)}
			</Grid>
		</AuthMainWrapper>
	)
}