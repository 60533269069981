import React from 'react';
import {useSelector} from "react-redux";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Login from "./component/auth/Login";
import Dashboard from "./component/dashboard";
import PasswordForgotten from "./component/auth/PasswordForgotten";
import ActivateAccount from "./component/auth/ActivateAccount";
import MainLayout from "./component/MainLayout";
import Company from "./component/dashboard/Company";
import Files from "./component/dashboard/Files";
import Decont from "./component/dashboard/Decont";
import PasswordForgotten2 from "./component/auth/PasswordForgotten2";
import Register from "./component/auth/Register";

function RoutedApp() {
	const { sessionActive } = useSelector(state => state.user);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						sessionActive===undefined ?
							(<div>loading</div>)
							:
							(sessionActive?
									<Navigate to="/dashboard" />
									:
									<Navigate to="/login" />
							)
					}
				/>
				<Route
					path="/login"
					element={
						sessionActive?
							<Navigate to="/dashboard" />
							:
							<Login/>
					}
				/>
				<Route
					path="/register"
					element={
						sessionActive?
							<Navigate to="/dashboard" />
							:
							<Register/>
					}
				/>
				<Route
					path="/activate-account/:hash"
					element={
						sessionActive?
							<Navigate to="/dashboard" />
							:
							<ActivateAccount />
					}
				/>
				<Route
					path="/forgot-password"
					element={
						sessionActive?
							<Navigate to="/dashboard" />
							:
							<PasswordForgotten/>
					}
				/>
				<Route
					path="/forgot-password/:hash"
					element={
						sessionActive?
							<Navigate to="/dashboard" />
							:
							<PasswordForgotten2/>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<MainLayout>
							<Dashboard />
						</MainLayout>
					}
				/>
				<Route
					path="/company"
					element={
						<MainLayout>
							<Company />
						</MainLayout>
					}
				/>
				<Route
					path="/files"
					element={
						<MainLayout>
							<Files />
						</MainLayout>
					}
				/>
				<Route
					path="/decont"
					element={
						<MainLayout>
							<Decont />
						</MainLayout>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default RoutedApp;