import NavItem from "./NavItem";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import FolderIcon from "@mui/icons-material/Folder";
import {useSelector} from "react-redux";
import {CardGiftcard} from "@mui/icons-material";

const MenuList = () => {
  const { data } = useSelector(state => state.user);
  const companyState = data.LockCompany ? 'checked' : 'pending';
  const filesState = data.LockFiles ? 'checked' : 'pending';
  const decontState = data.LockDecont;

  return <>
    <NavItem
        key='pages'
        item={{
          id: 'pages',
          title: 'Acasa',
          type: 'item',
          url: '/dashboard',
          icon: DashboardIcon,
        }}
        level={0}
    />
    <NavItem
        key='company'
        item={{
          id: 'company',
          title: 'Pasul 1: Formular de inscriere',
          type: 'item',
          url: '/company',
          icon: AddBusinessIcon,
          state: companyState,
        }}
        level={0}
    />
    <NavItem
        key='files'
        item={{
          id: 'files',
          title: 'Pasul 2: Fisiere',
          type: 'item',
          url: '/files',
          icon: FolderIcon,
          state: filesState,
        }}
        level={0}
    />
      {(data.state === "accepted" || data.state === "decont" || data.state === "decont_accepted") &&
      <NavItem
          key='decont'
          item={{
              id: 'decont',
              title: 'Pasul 3: Decont',
              type: 'item',
              url: '/decont',
              icon: CardGiftcard,
              state: decontState,
          }}
          level={0}
      />
      }
  </>;
};

export default MenuList;
