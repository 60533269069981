import DashboardIcon from '@mui/icons-material/Dashboard';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import FolderIcon from '@mui/icons-material/Folder';
import {CardGiftcard} from "@mui/icons-material";

const menuItems = {
	items: [
		{
			id: 'pages',
			title: 'Acasa',
			type: 'item',
			url: '/dashboard',
			icon: DashboardIcon,
		},
		{
			id: 'company',
			title: 'Pasul 1: Datele companiei',
			type: 'item',
			url: '/company',
			icon: AddBusinessIcon,
		},
		{
			id: 'files',
			title: 'Pasul 2: Fisiere',
			type: 'item',
			url: '/files',
			icon: FolderIcon,
		},
		{
			id: 'decont',
			title: 'Pasul 3: Decontare',
			type: 'item',
			url: '/decont',
			icon: CardGiftcard,
		},
	]
};

export default menuItems;
