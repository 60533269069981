import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {Box, Button, Drawer, useMediaQuery} from '@mui/material';
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import {useDispatch, useSelector} from "react-redux";
import UserApi from "../../../api/UserApi";
import {useState} from "react";
import {setUser} from "../../../store/userSlice";

const drawerWidth = 260;

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))
    const { token } = useSelector(state => state.user)
    const { data:dataUser } = useSelector(state => state.user)
    const [sended, setSended] = useState(false);
    const dispatch = useDispatch()

    const handleReject = () => {
        setSended(false);
        UserApi.reject(token).then(async (response)=>{
            await dispatch(setUser(response.data.user));
        }).catch((err)=>{
            console.log("err",err);
        })
    }

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                <LogoSection />
            </Box>
            </Box>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                    <div
                        style={{
                            display:'flex',
                            justifyContent: 'center',
                            marginTop:'32px',
                        }}
                    >
                        {!sended &&
                            <>
                                {dataUser.state === 'pending' &&
                                    <div style={{
                                        fontWeight: 'bold',
                                        color: 'orange'
                                    }}
                                    >Solicitarea este in verificare</div>
                                }
                                {dataUser.state === 'accepted' &&
                                    <div style={{
                                        display: 'block',

                                    }}>
                                        <div style={{
                                            fontWeight: 'bold',
                                            color: 'green'
                                        }}
                                        >
                                            Solicitarea este aprobata
                                        </div>
                                        {/*<Button*/}
                                        {/*    variant="contained"*/}
                                        {/*    onClick={handleReject}*/}
                                        {/*    style={{*/}
                                        {/*        background:'red',*/}
                                        {/*        marginTop: '16px',*/}
                                        {/*        fontWeight: 'bold',*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    Anuleaza inscrierea*/}
                                        {/*</Button>*/}
                                    </div>

                                }
                                {dataUser.state === 'rejected' &&
                                    <div style={{
                                        fontWeight: 'bold',
                                        color: 'red'
                                    }}>Solicitarea este respinsa</div>
                                }
                            </>
                        }
                        {sended &&
                            <>
                                <div style={{
                                        fontWeight: 'bold',
                                        color: 'green'
                                    }}
                                >
                                    Inscrierea s-a efectuat cu succes
                                </div>
                            </>
                        }


                </div>
            </Box>
        </>
    );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
