import {Button, FormHelperText, Grid, Typography} from '@mui/material';
import MainCard from '../ui/cards/MainCard';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import UserApi from "../../api/UserApi";
import SubCard from "../ui/cards/SubCard";
import DownloadIcon from "@mui/icons-material/Download";
import Dropzone from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {useTheme} from "@mui/material/styles";

const Dashboard = () => {
	const { data:dataUser } = useSelector(state => state.user)
	const [loaded, setLoaded] = useState(false)
	const { token } = useSelector(state => state.user)
	const [defaultFiles, setDefaultFiles] = useState([])
	const [userFiles, setUserFiles] = useState([])
	const [data, setData] = useState({})
	const [errorData, setErrorData] = useState({1:'',2:'',3:'',4:'',5:'',6:'',7:'', 8:''})
	const theme = useTheme();



	console.log("dataUser.state",dataUser.state);

	useEffect(()=>{
		if(!loaded){
			UserApi.getGrantFiles(token).then(async (response)=>{
				const defaultData = response.data.data;
				setDefaultFiles(defaultData);
			}).catch((err)=>{
				console.log("err",err);
			}).finally(()=>{
				setLoaded(true);
			})

			UserApi.getUserFiles(token).then(async (response)=>{
				setUserFiles(response.data.data);
			}).catch((err)=>{
				console.log("err",err);
			}).finally(()=>{
				setLoaded(true);
			})

		}
	},[loaded, token])

	const setFile = (file, position) => {
		setData((prevState)=>{
			return {
				...prevState,
				[position]: file,
			}
		})

		setErrorData((prevState)=>{
			return {
				...prevState,
				[position]:''
			}
		})
	}

	const returnFileStatus = (userFile) => {
		if(userFile.state === "pending"){
			return "in procesare.";
		}
		if(userFile.state === "accepted"){
			return "aprobat.";
		}
		if(userFile.state === "rejected"){
			if(userFile.message.length>0){
				return " respins cu mesajul "+userFile.message+".";
			}
			return " respins.";
		}
		return "";
	}

	return (
		<MainCard title="Bine ai venit">
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4">
						Sesiunea de inscriere s-a finalizat! <br/>
						Va multumim pt interes si va asteptam la TIMM Cluj in perioada 22-24 noiembrie , organizat la Cluj Innovation Park!
					</Typography>
					{dataUser.state === 'accepted' &&
						<Grid container spacing={6}>
							{defaultFiles.map((defaultFile, index) => {
								let fileDb = null;
								let fileUploaded = null;
								let lockDropzone = false;
								const filesFiltered = userFiles.filter(uf=>uf.id_default_file === defaultFile.id);
								if(filesFiltered.length>0){
									fileDb = filesFiltered[0];
									if(fileDb.state === "pending" || fileDb.state === "accepted"){
										lockDropzone = true;
									}
								}
								if(data[defaultFile.id]){
									fileUploaded = data[defaultFile.id];
								}

								let dropzoneBorderColor = '#2196f3';
								if(lockDropzone){
									dropzoneBorderColor = '#e3e3e3';
								}

								let borderColor = theme.palette.primary.light;
								if(errorData[defaultFile.id]){
									borderColor = 'red';
								}
								if(fileDb && fileDb.state === 'accepted'){
									borderColor = 'green';
								}

								let colorText = "black";
								if(fileDb && fileDb.state === "pending"){
									colorText = "orange";
								}
								if(fileDb && fileDb.state === "accepted"){
									colorText = "green";
								}
								if(fileDb && fileDb.state === "rejected"){
									colorText = "red";
								}

								return (
									<Grid item xs={12} sm={6} md={4} key={defaultFile.id}>
										<SubCard title={defaultFile.name} borderColor={borderColor}>
											{defaultFile.path.length > 0 &&
												<Button
													href={process.env.REACT_APP_HOST_API + defaultFile.path}
													download={true}
													variant="contained"
													startIcon={<DownloadIcon/>}
												>
													Descarca formular
												</Button>
											}
											<Dropzone
												multiple={false}
												accept={{
													'application/pdf': ['.pdf']
												}}
												onDrop={acceptedFiles => setFile(acceptedFiles[0], defaultFile.id)}
												disabled={lockDropzone}
											>
												{({getRootProps, getInputProps}) => (
													<section
														{...getRootProps()}
														style={{
															border: `3px dotted ${dropzoneBorderColor}`,
															textAlign: 'center',
															marginTop: '16px',
															padding: '16px',
															cursor: 'pointer',
															opacity: lockDropzone? 0.5 : 1
														}}
													>
														<div >
															<FileUploadIcon />
															<input {...getInputProps()} />
															<p style={{
																margin:0,
																padding:0
															}}>
																Incarca formularul completat
															</p>
														</div>
													</section>
												)}
											</Dropzone>
											{fileUploaded &&
												<Typography variant="h5">
													{fileUploaded.name}
												</Typography>
											}
											{fileDb &&
												<Typography
													variant='h5'
													style={{
														marginTop: "16px",
														fontWeight: 500,
														color: colorText,
													}}
												>
													Fisierul incarcat este {returnFileStatus(fileDb)}
												</Typography>
											}

											{errorData[defaultFile.id] &&
												<FormHelperText className="errorTextField">{errorData[defaultFile.id]}</FormHelperText>
											}

										</SubCard>
									</Grid>
								)
							})}
						</Grid>
					}
				</Grid>
			</Grid>
		</MainCard>
	)
};

export default Dashboard;
